import { type FC, type ReactNode, useMemo } from 'react';

import { Icon, type IconTypes } from '@vakantiesnl/components/src/atoms/Icon';
import { Typography } from '@vakantiesnl/components/src/atoms/Typography';
import ImageComponent from '@vakantiesnl/image-component';
import { useAsset } from '@vakantiesnl/services/src/hooks/contentful';

import { useCustomStyles } from './Alert.style';
import { useBreakpoints } from '../../utils/useBreakpoints';
import { Button } from '../Button';

export type AlertVariant = 'success' | 'warning' | 'info' | 'error';

type optionalAlertProps =
	| { title: string; text: ReactNode }
	| { title?: string; text: ReactNode }
	| { title: string; text?: ReactNode };

type AlertProps = optionalAlertProps & {
	id?: string;
	variant?: AlertVariant;
	className?: string;
	withIcon?: boolean;
	/*
	 * A microcopy string containing the asset ID for an image.
	 * Pass the microcopy with the specific asset ID for the image you want to display.
	 */
	imageMicrocopy?: string;
	alt?: string;
	/** Custom icon to overrule the variant icon */
	customIcon?: IconTypes;
	hyperlink?: {
		title: string;
		link: string;
	} | null;
};

const getIconType: Record<AlertVariant, 'checkmark' | 'info' | 'warning' | 'cancel'> = {
	success: 'checkmark',
	warning: 'warning',
	info: 'info',
	error: 'cancel',
};

export const Alert: FC<AlertProps> = ({
	title,
	text,
	variant = 'success',
	className,
	withIcon = true,
	imageMicrocopy,
	alt,
	id,
	customIcon,
	hyperlink,
}) => {
	const { classes, cx } = useCustomStyles(variant);
	const placeholderImage = useAsset(imageMicrocopy);
	const { isTabletOrIsDesktop } = useBreakpoints();

	const imageSource: string | undefined = useMemo(() => {
		if (typeof placeholderImage?.fields?.file.url === 'string') return placeholderImage.fields.file.url;
		return undefined;
	}, [placeholderImage]);

	return (
		<div className={cx(classes.container, classes[variant], className)} data-cy="alert-container" id={id}>
			{withIcon && <Icon size="medium" type={customIcon ?? getIconType[variant]} className={classes.icon} />}
			<div className={classes.alertTitleTextWrapper}>
				{title && (
					<Typography variant="headingXs" as="h6" className={classes.title}>
						{title}
					</Typography>
				)}
				{text && (
					<Typography variant={isTabletOrIsDesktop ? 'bodyMd' : 'bodySm'} as="div" className={classes.text}>
						{text}
					</Typography>
				)}
				{hyperlink && (
					<Button className={classes.button} size="small" asText href={hyperlink.link}>
						{hyperlink.title}
					</Button>
				)}
			</div>
			{imageSource && (
				<div className={classes.alertImageContainer}>
					<ImageComponent
						useLoader="Contentful"
						src={imageSource}
						alt={alt ? alt : ''}
						width={114}
						height={40}
					/>
				</div>
			)}
		</div>
	);
};
